import React from 'react';
import SEO from '../components/seo';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../templates/layout';
import Highlight from '../components/highlight';
import Companies from '../components/companies.js';
import { ProgrammDescription } from '../components/features.js';
import useScreenSize from 'use-screen-size';

const IndexPage = (props) => {
  const { site } = useStaticQuery(query);
  const screenSize = useScreenSize();

  return (
    <Layout
      buttonType='help'
      screenSize={screenSize}
      path={props.location.pathname}
    >
      <SEO params={site.siteMetadata} externalCssModules={externalCssModules} />
      <div className='flex flex-col space-y-[145px]  md:space-y-[195px] px-[16px] pb-[75px]'>
        <Highlight screenSize={screenSize} />
        <Companies screenSize={screenSize} />
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        titleTemplate
        description
        url
        image
      }
    }
  }
`;

// video styles
const externalCssModules = [
  <link
    rel='stylesheet'
    href='https://cdn.jsdelivr.net/npm/@vime/core@^5/themes/default.css'
  />,
  <link
    rel='stylesheet'
    type='text/css'
    charset='UTF-8'
    href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
  />,
  <link
    rel='stylesheet'
    type='text/css'
    href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
  />,
];

export default IndexPage;
