import React from 'react';

import { Title, Subtititle } from './typography';
import { Diveder } from './elements';
import { TextBlock } from './feature_elements';
import LeadForm from './lead_form';
import VideoReviews from './video_reviews';

const Highlight = ({ screenSize }) => {
  return (
    <div className='pt-[100px] flex justify-start md:justify-center lg:justify-start'>
      <div className='lg:grid lg:grid-cols-5 row-span-1 gap-24'>
        <div className='col-span-2 items-center w-full'>
          <TextContent />
        </div>
        <div className='col-span-3 self-center'>
          {['xs', 's', 'm'].includes(screenSize.screen) && (
            <Subtititle>❤️ Don't just take our word for it</Subtititle>
          )}
          <VideoReviews screenSize={screenSize} />
        </div>
      </div>
    </div>
  );
};

const TextContent = () => {
  return (
    <TextBlock>
      <Title level={'H1'}>
        {' '}
        Say goodbye to forms, and hello to interviews!{' '}
      </Title>
      <Diveder />
      <Subtititle>
        Sit back and relax as we apply you to job openings that match your
        skills, location, and preferences. Our proven approach helps our clients
        secure a job within 0 to 3 months.
      </Subtititle>

      <div className='pt-[10px] pb-[25px] w-full mx-auto'>
        <LeadForm />
      </div>
    </TextBlock>
  );
};

const query = graphql`
  query {
    mobileBanner: file(relativePath: { eq: "mobile/highlight_banner.png" }) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    girlGetAjob: file(relativePath: { eq: "girl_get_a_job.png" }) {
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    background: file(relativePath: { eq: "highlight_background.png" }) {
      name
      childImageSharp {
        gatsbyImageData(
          width: 1170
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Highlight;
