import React, { useState, Suspense } from 'react';
import { videos } from './collections';
import { lazily } from 'react-lazily';
import { useStopwatch } from 'react-timer-hook';
import { useSwipeable } from 'react-swipeable';
import '@vime/core/themes/default.css';

const {
  Video,
  Player,
  Ui,
  ClickToPlay,
  DefaultUi,
  Scrim,
  Controls,
  PlaybackControl,
} = lazily(() => import('@vime/react'));

const VideoReviews = ({ screenSize: { screen } }) => {
  return (
    <div className=' my-auto'>
      <LargeScreen />
    </div>
  );
};

const LargeScreen = () => {
  const [currentVideo, setCurrentVideo] = useState(videos[0]);
  const { seconds, isRunning, pause, reset } = useStopwatch({
    autoStart: true,
  });

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      pause();
      const { dir: direction } = eventData;
      const currentVideoIndex = currentVideo.index;
      const nextVideoIndex =
        currentVideoIndex + 1 > videos.length - 1 ? 0 : currentVideoIndex + 1;
      const prevVideoIndex =
        currentVideoIndex - 1 < 0 ? videos.length - 1 : currentVideoIndex - 1;

      const videoIndexFromDirection =
        direction === 'Left' ? prevVideoIndex : nextVideoIndex;

      setCurrentVideo(videos[videoIndexFromDirection]);
      reset();
    },
  });

  if (seconds > 5) {
    const currentVideoIndex = currentVideo.index;
    const nextVideoIndex =
      currentVideoIndex + 1 > videos.length - 1 ? 0 : currentVideoIndex + 1;
    setCurrentVideo(videos[nextVideoIndex]);
    reset();
  }
  return (
    <div {...handlers} className='w-full '>
      <VideoBlock
        pauseTimer={pause}
        isTimerRunning={isRunning}
        resetTimer={reset}
        index={currentVideo}
        currentVideo={currentVideo}
      />
      {/* <SideVideosMenu
        setCurrentVideo={setCurrentVideo}
        currentVideo={currentVideo}
      /> */}
      <SelectedVideoDesign
        name={currentVideo.name}
        linkedin={currentVideo.linkedin}
        role={currentVideo.role}
        index={currentVideo.index}
      />
    </div>
  );
};

const SelectedVideoDesign = ({ name, linkedin, role, index }) => {
  return (
    <div key={`selected_video_${index}`}>
      <div className='bg-gray-light text-[14px] py-[20px] px-[16px] rounded-t-md'>
        <div>{name}</div>
        <div className='text-gray-main text-[12px] pt-2'>{role}</div>
      </div>
      <LinkedinBlock linkedin={linkedin} />
    </div>
  );
};

const UnselectedVideoDesign = ({ name, index, setCurrentVideo }) => {
  return (
    <div
      key={`unselected_video_${index}`}
      className='cursor-pointer  text-[12px] py-[20px] px-4'
      onClick={() => setCurrentVideo(videos[index])}
    >
      {name}
    </div>
  );
};

const VideoBlock = ({
  pauseTimer,
  isTimerRunning,
  resetTimer,
  currentVideo,
  index,
}) => {
  const { videoUrl } = currentVideo;
  // suspence not work without isSSR
  const isSSR = typeof window === 'undefined';
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<>Loading...</>}>
          <div key={`video_block_${index}`}>
            <Player
              autopause
              pause
              key={`player_index_${index}`}
              onClick={() => {
                if (isTimerRunning) {
                  pauseTimer();
                } else {
                  resetTimer();
                }
              }}
            >
              <Video>
                {/* These are passed directly to the underlying HTML5 `<video>` element. */}
                {/* Why `data-src`? Lazy loading, you can always use `src` if you prefer.  */}
                <source data-src={videoUrl} type='video/mp4' />
              </Video>
              <Ui>
                <ClickToPlay />
              </Ui>
              <DefaultUi noControls>
                <Scrim />

                <Controls pin='center'>
                  <PlaybackControl
                    hideTooltip
                    style={{ '--vm-control-scale': 1.7 }}
                  />
                </Controls>
              </DefaultUi>
            </Player>
          </div>
        </Suspense>
      )}
    </>
  );
};

const LinkedinBlock = ({ linkedin }) => {
  return (
    <a rel='nofollow ' className='cursor-pointer' href={linkedin}>
      <div className='text-center text-[12px] py-[8px] rounded-b-md bg-gray-semilight'>
        <noindex> Linkedin →</noindex>
      </div>
    </a>
  );
};

export default VideoReviews;
