import React from 'react';

const changeComponentsOrderOnScreen = (components, screen) => {
  const maybeReversed = ['l', 'xl'].includes(screen)
    ? components.reverse()
    : components;

  return maybeReversed;
};
const FeatureGrid = (props) => {
  return (
    <div className='flex flex-col lg:flex-row space-x-0 lg:space-x-[48px] xl:space-x-[96px] space-y-[125px] lg:space-y-0 items-center  '>
      {props.children}
    </div>
  );
};

const TextBlock = (props) => {
  return (
    <div className='flex flex-col justify-start text-left md:text-center lg:text-left items-start md:items-center lg:items-start md:max-w-md mx-auto '>
      {props.children}
    </div>
  );
};

export { FeatureGrid, TextBlock, changeComponentsOrderOnScreen };
