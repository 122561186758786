import React from 'react';
import { Title, Subtititle } from './typography';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
const Companies = ({ screenSize: { screen } }) => {
  const {
    graphCmsLanding: { brandsLogos },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      graphCmsLanding {
        brandsLogos {
          gatsbyImageData(height: 65)
        }
      }
    }
  `);

  const chunksNum = ['xs', 's'].includes(screen)
    ? 7
    : ['xl'].includes(screen)
    ? 20
    : 13;
  const chunkedImagesOnRows = chunkArray(brandsLogos, chunksNum);

  return (
    <div className='text-center'>
      <Title level={'h4'}>Are you looking for interviews?</Title>
      <Subtititle>
        We can help! We provide job seekers access to over{' '}
        <span className='underline'>65,000+</span> companies in the US 😊
      </Subtititle>
      <div className='full-bleed pt-[25px]'>
        {chunkedImagesOnRows.map((row, i) => {
          const n = i + 1;
          const maybeRevereScroller =
            n % 2 === 1 ? 'scrollerReverse' : 'scroller';
          return (
            <div className='flex  justify-center'>
              <div
                className={` ${maybeRevereScroller} flex  items-center mx-[-300px]`}
              >
                {row.map((brandLogo) => (
                  <LogoCard>
                    <GatsbyImage
                      image={brandLogo.gatsbyImageData}
                      alt={brandLogo.fileName}
                      layout={'fullWidth'}
                    />
                  </LogoCard>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const LogoCard = (props) => {
  return (
    <div className='h-[100px] w-[150px] m-[25px]'>
      <div className='w-full h-full'>{<div>{props.children}</div>}</div>
    </div>
  );
};

const chunkArray = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};
export default Companies;
