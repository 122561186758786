const videosWithoutIndex = [
  {
    name: 'Yahya Hafez',
    role: 'Full Stack Developer',
    videoUrl: 'https://careerypro.b-cdn.net/yahya_hafez.mp4',
    linkedin: 'https://www.linkedin.com/in/yahya-hafez',
  },
  {
    name: 'Daniel Michael',
    role: 'Full Stack Developer',
    videoUrl: 'https://careerypro.b-cdn.net/daniel_michael.mp4',
    linkedin:
      'https://www.linkedin.com/in/%F0%9F%91%A8%F0%9F%8F%BD%E2%80%8D%F0%9F%92%BB-daniel-michael-718825155/',
  },
  {
    name: 'Hariharan Shankar',
    role: 'Software Engineer',
    videoUrl: 'https://careerypro.b-cdn.net/hariharan_shankar.mp4',
    linkedin: 'https://www.linkedin.com/in/hariharan9731/',
  },
  {
    name: 'Justice Jares',
    role: 'Full Stack Developer',
    videoUrl: 'https://careerypro.b-cdn.net/justice_jares.mp4',
    linkedin: 'https://www.linkedin.com/in/jaresj/',
  },
  {
    name: 'Puneeth Rangaswamy',
    role: 'Software Engineer',
    videoUrl: 'https://careerypro.b-cdn.net/puneeth_rangaswamy.mp4',
    linkedin: 'https://www.linkedin.com/in/puneeth-rangaswamy-79b25b125/',
  },
];

const videos = videosWithoutIndex.map((video, index) => {
  const i = { index: index };
  const merged = { ...i, ...video };
  return merged;
});

export { videos };
